document.addEventListener('DOMContentLoaded', () => {
  showAverageRateOnMonthSelection();
  $('#dollar-rates-month').select2();
  render_dollar_rates_table();
});

const showCurrentMonthMessage =() => {
  let currentDate = new Date();
  let lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  let formatted_current_date = currentDate.toDateString().slice(4,7) + currentDate.toDateString().slice(10,15)
  if($('#dollar_rate_month_drop').val().toString() == formatted_current_date &&
   currentDate.toDateString() != lastDayOfMonth.toDateString()){
    $('#success-message').show();
    append_success_message([`This rate is partially calculated based on the days till date for this month `], '', $('#success-message'));
  }
  else{
    $('#success-message').hide();
  }
}

const showAverageRateOnMonthSelection = () => {
  $('#dollar_rate').select2();
  $('#dollar_rate_month_drop').datetimepicker({
    format: window.MONTH_YEAR_FORMAT,
    viewMode: 'months',
    date: moment($('#dollar_rate_month_drop').data('default')),
    maxDate: moment($('#dollar_rate_month_drop').data('max-month')),
    minDate: moment($('#dollar_rate_month_drop').data('min-month')),
  }).on('dp.change', (e) => {
    if ($(e.target).val()) {
      $.ajax({
        'type': 'get',
        'dataType': 'json',
        'url': '/currency_rates',
        'data': { dollar_rate_month: $(e.target).val(), show_average_rate: true },
        success(response) {
          if (response.average_rate != null) {
            $('.average_dollar_rate').html(`Average Rate: ${response.average_rate}`)
            showCurrentMonthMessage();
          } else {
            $('.average_dollar_rate').html(`${response.warning_message}`)
          }
        }
      });
    } });
}

const render_dollar_rates_table = () => {
  $('#dollar-rates-table').DataTable().destroy();
  $('#dollar-rates-table').DataTable({
    fixedHeader: true,
    processing: true,
    serverSide: true,
    ajax: {
      'type': 'get',
      'url': '/currency_rates',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($('#dollar-rates-table').DataTable().page.info().page) + 1
        data.per_page = $('#dollar-rates-table').DataTable().page.info().length
        data.order = $('#dollar-rates-table').DataTable().order()[0][0]
        data.direction = $('#dollar-rates-table').DataTable().order()[0][1]
        data.search = data.search.value
        data.dollar_rate_month = $("#dollar-rates-month").find("option:selected").text()
      }
    }
  });
}

$(document).on('change', '#dollar-rates-month', () => {
  render_dollar_rates_table();
  $.ajax({
    'type': 'get',
    'dataType': 'script',
    'url': '/currency_rates',
    'data': { dollar_rate_month: $("#dollar-rates-month").find("option:selected").text(), show_average_rate: true },
  })
});
